import { render, staticRenderFns } from "./EdenImage.vue?vue&type=template&id=c9732390&scoped=true"
import script from "./EdenImage.vue?vue&type=script&lang=js"
export * from "./EdenImage.vue?vue&type=script&lang=js"
import style0 from "./EdenImage.vue?vue&type=style&index=0&id=c9732390&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c9732390",
  null
  
)

export default component.exports